import { useWindowDimensions } from '../../hooks';
import React, { useState } from 'react';
import { Section } from '../Section/Section';
import { WordIntervalTypography } from '../Content/WordInterval';
import { Button } from '@mui/material';
import { ACN_CLOUD_ROUTE } from '../../pages/routes';
import AppLink from '../Link/AppLink';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

export function CommonCTASection() {
  const { isMobile } = useWindowDimensions();
  const [visible, setVisible] = useState<boolean>();

  const beenVisible = !!visible;

  return (
    <Section
      title={
        <>
          Begin vandaag nog met {isMobile ? ' ' : <br />}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['een', 'betere', 'écht']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['slimmere', 'strategische', 'onderscheid']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['strategie.', 'keuzes.', 'maken.']}
          />
        </>
      }
      backgroundColor="transparent"
      backgroundPosition={'center bottom'}
      backgroundImage="https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/acn_cloud_rocket_v5.svg"
      position="center"
      subtitle={
        'Met ons krachtige platform krijg je toegang tot de ontbrekende puzzelstukken voor zowel je online als offline strategie. Kies ervoor om strategische besluiten te nemen op basis van data. Stap vandaag nog in de meertrapsraket voor rendementsgroei.'
      }
      caption={'Haal meer rendement met slimme tools op één plek.'}
      onChangeInViewport={(inViewPort) =>
        !beenVisible && setVisible(inViewPort)
      }
      SectionContentBoxProps={{
        sx: {
          transition: 'transform 0.3s',
          transform: visible ? 'scale(1.2)' : 'unset',
        },
      }}
      SectionBoxProps={{
        sx: {
          pt: 30,
          pb: isMobile ? 24 : 30,
          px: visible ? 6 : 0,
          backgroundSize: 'cover',
          backgroundPositionY: visible ? 0 : 600,
          transition: 'background 0.6s',
          backgroundPositionX: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage:
            'url(https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/acn_cloud_rocket_v8.svg)',
        },
      }}
      actions={[<DiscoverACNCloudCTA key={0} />, <ContactCTA key={1} />]}
    />
  );
}

const DiscoverACNCloudCTA = () => (
  <Button
    fullWidth
    key={0}
    variant="contained"
    size={'large'}
    href={ACN_CLOUD_ROUTE}
    component={AppLink}
    color="primary"
    endIcon={<ArrowForwardIcon />}
  >
    Ontdek ACN Cloud
  </Button>
);

const ContactCTA = () => (
  <Button
    fullWidth
    key={1}
    variant={'outlined'}
    color={'primary'}
    size={'large'}
    startIcon={<WhatsAppIcon />}
    href="https://wa.me/31850604011"
    target={'_blank'}
  >
    Vragen via WhatsApp
  </Button>
);
