import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Icon,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { ACN_CLOUD_ROUTE, COMMUNIQATE_ROUTE, MARQTING_ROUTE } from './routes';
import AppLink from '../components/Link/AppLink';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { WordIntervalTypography } from '../components/Content/WordInterval';
import React, { useState } from 'react';
import { Section } from '../components/Section/Section';
import Image from 'next/image';
import { Page } from '../components/Page/Page';
import { useWindowDimensions } from '../hooks';
import { CommonCTASection } from '../components/Sections/CommonCTASection';
import { CommuniqateDarkTheme } from '../themes/communiqate-dark.theme';
import { HighLightedText } from '../components/HighLightedText/HighLightedText';
import { MarqtingDarkTheme } from '../themes/marqting-dark.theme';
import InsightsIcon from '@mui/icons-material/Insights';
import EuroIcon from '@mui/icons-material/Euro';
import { DemoCTA } from '../components/Buttons/DemoCTA';

export default function HomePage() {
  return (
    <Page
      title={'Technologie op een persoonlijke en slimme manier.'}
      seo={{
        description:
          'Onze slimme producten op ons krachtige platform zorgen voor persoonlijke benadering en schaalbare groei. Deze unieke combinatie ondersteunt de bedrijfsvoering met slimme oplossingen voor maximaal rendement.',
        openGraph: {
          type: 'website',
          locale: 'nl_NL',
          title:
            'Technologie op een persoonlijke en slimme manier. | AppCenter Nederland',
          description:
            'Onze slimme producten op ons krachtige platform zorgen voor persoonlijke benadering en schaalbare groei. Deze unieke combinatie ondersteunt de bedrijfsvoering met slimme oplossingen voor maximaal rendement.',
          images: [
            {
              url: 'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/featured_img_home_v2.jpg',
              secureUrl:
                'https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/featured_img_home_v2.jpg',
              width: 1920,
              height: 1080,
              alt: 'Onze slimme producten op ons krachtige platform zorgen voor persoonlijke benadering en schaalbare groei. Deze unieke combinatie ondersteunt de bedrijfsvoering met slimme oplossingen voor maximaal rendement.',
              type: 'image/jpeg',
            },
          ],
          site_name: 'AppCenter Nederland',
        },
      }}
    >
      {/*<Section4 />*/}
      <Section1 />
      <Section2 />
      <Section3 />
      <CommonCTASection />
    </Page>
  );
}

function Section1() {
  const [visible, setVisible] = useState<boolean>(false);
  const { isMobile } = useWindowDimensions();
  return (
    <Section
      disableFade
      onChangeInViewport={setVisible}
      backgroundImage="https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/acn_hero_image_v2.svg"
      position="left"
      title={
        <>
          Wij helpen ambitieuze ondernemers die graag
          {isMobile ? ' ' : <br />}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['willen', 'veel', 'meer', 'beter']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['opschalen', 'efficiënter', 'omzet', 'klanten']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['en', 'willen', 'willen', 'willen']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['groeien.', 'werken.', 'behalen.', 'bedienen.']}
          />
        </>
      }
      subtitle={
        'Onze slimme producten, op ons krachtige platform, zorgen voor persoonlijke benadering en schaalbare groei. Deze unieke combinatie ondersteunt de bedrijfsvoering met slimme oplossingen voor maximaal rendement.'
      }
      caption={
        <>
          <Box sx={{ pb: 2 }}>
            <Image
              alt="logo"
              src="https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/acn_logo_white.svg"
              height="70"
              width="400"
            />
          </Box>
          Wij zetten technologie op een persoonlijke en slimme manier in.
        </>
      }
      SectionBoxProps={{
        sx: {
          backgroundRepeat: 'no-repeat',
          backgroundPosition: { xs: 'center 95%', lg: 'center right' },
          display: 'flex',
          alignItems: 'center',
          pb: { xs: 50, lg: 50 },
          backgroundSize: { xs: '70%', lg: '40%' },
        },
      }}
      actions={[<DiscoverACNCloudCTA key={0} />, <ContactCTA key={1} />]}
    />
  );
}

function Section2() {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <Section
      onChangeInViewport={setVisible}
      title={
        <>
          Wij slaan de brug tussen <br />
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['online', 'mens', 'persoonlijk']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['en', 'en', 'en']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['offline.', 'technologie.', 'groei.']}
          />
        </>
      }
      backgroundImage="https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/bridge_online_offline_v2.svg"
      backgroundColor={'#061949'}
      backgroundPosition={'18%'}
      position="right"
      subtitle={
        'Meten is weten. Neem op basis van data, zowel uit de online als offline wereld, slimme strategische besluiten. Wij overbruggen groei met directe persoonlijke benadering. Ons platform zorgt voor exponentiële meetbare groei.'
      }
      caption={'Met ons unieke online platform garanderen wij succes.'}
      SectionBoxProps={{
        sx: {
          backgroundSize: {
            xs: '100%',
            sm: '50%',
            md: '40%',
            lg: '40%',
            xl: '29%',
          },
          py: {
            lg: 30,
          },
        },
      }}
      actions={[<DiscoverACNCloudCTA key={0} />, <ContactCTA key={1} />]}
    />
  );
}

function Section3() {
  const [visible, setVisible] = useState<boolean>(false);
  return (
    <Section
      backgroundImage="https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/human_technology.svg"
      position="left"
      onChangeInViewport={setVisible}
      title={
        <>
          Benader jouw klanten <br />
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['zowel', 'waar', 'altijd']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['proactief', 'hun', 'met']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['als', 'aandacht', 'relevante']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'secondary' }}
            words={['persoonlijk.', 'is.', 'informatie.']}
          />
        </>
      }
      subtitle={
        'Zonder een schot hagel jouw potentiële klanten benaderen. Direct jouw doelgroep op een persoonlijke en relevante manier benaderen levert aanzienlijk hogere conversie aantallen op.'
      }
      caption={'Slimme technologie persoonlijk en direct inzetten.'}
      SectionBoxProps={{
        sx: {
          backgroundSize: {
            xs: '70%',
            sm: '50%',
            md: '40%',
            lg: '35%',
            xl: '30%',
          },
          backgroundPosition: {
            xs: 'center center',
            sm: 'center center',
            md: 'center center',
            lg: 'right 100px center',
            xl: 'right 100px center',
          },
          py: {
            lg: 20,
          },
        },
      }}
      actions={[<DiscoverACNCloudCTA key={0} />, <ContactCTA key={1} />]}
    />
  );
}

const DiscoverACNCloudCTA = () => (
  <Button
    fullWidth
    key={0}
    variant="contained"
    size={'large'}
    href={ACN_CLOUD_ROUTE}
    component={AppLink}
    color="primary"
    endIcon={<ArrowForwardIcon />}
  >
    Ontdek ACN Cloud
  </Button>
);

const ContactCTA = () => (
  <Button
    fullWidth
    key={1}
    variant={'outlined'}
    color={'primary'}
    size={'large'}
    startIcon={<WhatsAppIcon />}
    href="https://wa.me/31850604011"
    target={'_blank'}
  >
    Vragen via WhatsApp
  </Button>
);

function Section4() {
  return (
    <Section
      disableFade
      ContainerProps={{ maxWidth: false, sx: { px: '0 !important' } }}
      // onChangeInViewport={setVisible}
      // backgroundColor={'#061949'}
      position={'center'}
      SectionContentBoxProps={{ padding: '0 !important' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Card sx={{ p: 0 }}>
            <ThemeProvider theme={CommuniqateDarkTheme}>
              <CardContent
                sx={{
                  textAlign: 'center',
                  p: 0,
                  height: '80vh',
                  pb: '0 !important',
                }}
              >
                <CommuniQateCardHero />
              </CardContent>
            </ThemeProvider>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
          <Card sx={{ p: 0 }}>
            <ThemeProvider theme={MarqtingDarkTheme}>
              <CardContent
                sx={{
                  textAlign: 'center',
                  p: 0,
                  pb: '0 !important',
                  height: '80vh',
                }}
              >
                <MarQtingCardHero />
              </CardContent>
            </ThemeProvider>
          </Card>
        </Grid>
      </Grid>
    </Section>
  );
}

function CommuniQateCardHero() {
  const [visible, setVisible] = useState<boolean>(false);
  const Usp = ({ icon, label }) => (
    <Stack component="span" direction={'row'} spacing={2}>
      <Avatar
        component="span"
        variant={'rounded'}
        sx={{ bgcolor: 'white', width: 50, height: 50 }}
      >
        <Icon color={'primary'}>{icon}</Icon>
      </Avatar>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        component="span"
        variant={'body1'}
      >
        {label}
      </Typography>
    </Stack>
  );

  return (
    <Section
      onChangeInViewport={setVisible}
      position="center"
      SectionBoxProps={{
        pb: { xs: 50, lg: 0 },
        p: { xs: 0, lg: 10 },
        sx: { backgroundSize: { xs: 'contain', lg: '50%' } },
        minHeight: '80vh',
      }}
      backgroundPosition={'bottom right'}
      backgroundColor={'primary.main'}
      // backgroundImage="https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/tim_de_robot_alt.svg"
      caption={
        <>
          <Box sx={{ pb: 2 }}>
            <Image
              alt="logo"
              src="https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/logo_communiqate_white.svg"
              height="70"
              width="400"
            />
          </Box>
          Bereik klanten direct waar de aandacht zich bevindt.
        </>
      }
      titleTypographyProps={{ lineHeight: 1.5, fontSize: '2.5rem' }}
      title={
        <>
          TIM{' '}
          <HighLightedText
            TypographyProps={{ color: 'primary' }}
            text="transformeert"
          />{' '}
          <HighLightedText
            TypographyProps={{ color: 'primary' }}
            text="klantinteractie"
          />{' '}
          via Whatsapp Business op een persoonlijke manier. Dat zorgt voor{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'primary' }}
            words={['exponentiële', 'hogere', 'betere']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'primary' }}
            words={['omzetgroei.', 'klantretentie.', 'klantenservice.']}
          />
        </>
      }
      subtitle={
        <span>
          <strong>Relevante en persoonlijke benadering;</strong> daar geloven we
          in. We doen dat op een{' '}
          <strong>unieke manier via WhatsApp Business.</strong> Aandacht maakt
          alles mooier. Het <strong>slim opschalen</strong> van je organisatie
          kan <strong>met CommuniQate.</strong> TIM{' '}
          <strong>beantwoordt klantvragen, geeft productinformatie</strong>
          {''} en{''} <strong> handelt bestellingen af</strong> op een{' '}
          <strong>persoonlijke wijze.</strong> Zó <strong>help</strong> je
          klanten <strong>beter en verder zonder extra personeel.</strong>
          <Stack component="span" direction="column" spacing={1} sx={{ mt: 4 }}>
            <Usp
              icon={<WhatsAppIcon />}
              label={
                <Typography component="span">
                  <strong>Hoeveel promotionele e-mails</strong> lees je? En{' '}
                  <strong>hoeveel WhatsApp berichten?</strong> Precies!
                </Typography>
              }
            />
            <Usp
              icon={'diversity_1'}
              label={
                <Typography component="span">
                  <strong>Benader klanten proactief</strong> met eenvoud en
                  relevantie <strong>voor hogere conversie.</strong>
                </Typography>
              }
            />
          </Stack>
        </span>
      }
      actions={[
        <Button
          key={0}
          fullWidth
          variant="contained"
          size={'large'}
          href={COMMUNIQATE_ROUTE}
          component={AppLink}
          color="secondary"
          endIcon={<ArrowForwardIcon />}
        >
          Ontdek CommuniQate
        </Button>,
        <DemoCTA
          app={'communiqate'}
          key={1}
          variant="outlined"
          color="inherit"
        />,
      ]}
    />
  );
}

function MarQtingCardHero() {
  const [visible, setVisible] = useState<boolean>(false);
  const { isMobile } = useWindowDimensions();
  const Usp = ({ icon, label }) => (
    <Stack component="span" direction={'row'} spacing={2}>
      <Avatar
        component="span"
        variant={'rounded'}
        sx={{ bgcolor: 'white', width: 50, height: 50 }}
      >
        <Icon color={'primary'}>{icon}</Icon>
      </Avatar>
      <Typography
        sx={{ display: 'flex', alignItems: 'center' }}
        component="span"
        variant={'body1'}
      >
        {label}
      </Typography>
    </Stack>
  );

  return (
    <Section
      position="center"
      contentContainerProps={{ maxWidth: 'lg' }}
      onChangeInViewport={setVisible}
      SectionBoxProps={{
        pb: { xs: 35, lg: 0 },
        p: { xs: 0, lg: 10 },
        minHeight: '80vh',
        //   // sx: {
        //   //   backgroundSize: {
        //   //     xs: '90%',
        //   //     sm: '60%',
        //   //     md: '45%',
        //   //     lg: '42%',
        //   //     xl: '42%',
        //   //   },
        //   //   backgroundPosition: { xs: 'center bottom 40px', lg: 'bottom right' },
        //   },
      }}
      backgroundColor={'primary.main'}
      // backgroundImage="https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/marqting_image_first.svg"
      caption={
        <>
          <Box sx={{ pb: 2 }}>
            <Image
              alt="logo"
              src="https://appcenternederland-general.ams3.cdn.digitaloceanspaces.com/appcenternederland-website/marqting_logo_white.svg"
              height="70"
              width="300"
            />
          </Box>
          Neem betere strategische beslissingen op basis van data.
        </>
      }
      titleTypographyProps={{ lineHeight: 1.5, fontSize: '2.5rem' }}
      title={
        <>
          Maakt de offline wereld{' '}
          <HighLightedText
            TypographyProps={{ color: 'primary' }}
            text="meetbaar."
          />{' '}
          Dat zorgt voor betere beslissingen en {!isMobile ? ' ' : <br />}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'primary' }}
            words={['meer', 'efficiëntere', 'exponentiële']}
          />{' '}
          <WordIntervalTypography
            disabled={!visible}
            TypographyProps={{ color: 'primary' }}
            words={['rendement.', 'campagnes.', 'omzetgroei.']}
          />
        </>
      }
      subtitle={
        <span>
          Met <strong>passie en enthousiasme</strong> helpen wij organisaties
          met het invullen van <strong>ontbrekende puzzelstukken.</strong> We
          realiseren <strong>maximaal rendement</strong> door de brug te slaan
          tussen online en offline. Hier is <strong>MarQting</strong> het
          voorbeeld van. <strong>Fysieke producten</strong> of{' '}
          <strong>middelen</strong> maken wij <strong>meetbaar.</strong> Met{' '}
          <strong>slimme labels</strong> die altijd aanpasbaar zijn en{' '}
          <strong>meetbaar blijven.</strong>
          <Stack component="span" direction="column" spacing={1} sx={{ mt: 2 }}>
            <Usp
              icon={<InsightsIcon />}
              label={
                <Typography component="span">
                  <strong>Betere strategische keuzes</strong> op basis van{' '}
                  <strong>automatisch verzamelde data.</strong>
                </Typography>
              }
            />
            <Usp
              icon={<EuroIcon />}
              label={
                <Typography component="span">
                  <strong>Inzicht</strong> in investering versus rendement van{' '}
                  <strong>offline promoties.</strong>
                </Typography>
              }
            />
          </Stack>
        </span>
      }
      actions={[
        <Button
          key={0}
          fullWidth
          variant="contained"
          size={'large'}
          href={MARQTING_ROUTE}
          component={AppLink}
          color="secondary"
          endIcon={<ArrowForwardIcon />}
        >
          Ontdek MarQting
        </Button>,
        <DemoCTA app={'marqting'} key={1} variant="outlined" color="inherit" />,
      ]}
    />
  );
}
