import React, { useEffect, useState } from 'react';
import { TypographyProps } from '@mui/material';
import { HighLightedText } from '../HighLightedText/HighLightedText';

interface WordIntervalProps {
  disabled?: boolean;
  interval?: number;
  words: string[];
}

export function WordInterval(props: WordIntervalProps) {
  const { words, interval, disabled } = props;

  const [activeWordIndex, setActiveWordIndex] = useState<number>(0);

  useEffect(() => {
    const activeInterval = setInterval(() => {
      if (disabled) return;

      let newIndex = activeWordIndex + 1;

      if (newIndex > words.length - 1) {
        newIndex = 0;
      }
      setActiveWordIndex(newIndex);
    }, interval || 1750);

    return () => {
      clearInterval(activeInterval);
    };
  }, [interval, activeWordIndex, words.length, disabled]);

  return <>{words[activeWordIndex]}</>;
}

interface WordIntervalTypographyProps extends WordIntervalProps {
  TypographyProps: TypographyProps;
}

export const WordIntervalTypography = ({
  TypographyProps,
  ...WordIntervalProps
}: WordIntervalTypographyProps) => (
  <HighLightedText
    TypographyProps={TypographyProps}
    text={<WordInterval {...WordIntervalProps} />}
  />
);
