import { Button, ButtonProps } from '@mui/material';
import AppLink from '../Link/AppLink';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import React from 'react';

interface DemoCTAProps extends ButtonProps {
  inViewPort?: boolean;
  app: 'marqting' | 'communiqate';
}

export const DemoCTA = ({ inViewPort, app, ...ButtonProps }: DemoCTAProps) => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <Button
    fullWidth
    variant="contained"
    size={'large'}
    sx={{ transition: 'background-color 0.4s' }}
    href={
      app === 'communiqate'
        ? 'https://wa.me/31850604011?text=Demo%20aanvragen%20CommuniQate%20via%20Website'
        : 'https://wa.me/31850604011?text=Demo%20aanvragen%20MarQting%20via%20Website'
    }
    component={AppLink}
    target={'_blank'}
    color={inViewPort ? 'secondary' : 'primary'}
    endIcon={<WhatsAppIcon />}
    {...ButtonProps}
  >
    Demo aanvragen
  </Button>
);
